<template>
    <div class="holder">
        <div class="button-options">
            <button class="btn active-button">Profile</button>
            <button class="btn">Subscription</button>
        </div>

        <div class="profile-image-details mt-5">
            <div class="image-details">
                <img class="profile-image-holder" src="../assets/images/user-profile.jpg" alt="Profile Image" />
                <div class="camera-select">
                    <img class="" src="../assets/icons/camera.svg" alt="select">
                </div>
            </div>
            <div class="info-details">
                <h6>Charles Amos Ubokulom</h6>
                <h6>Shelta/54664/ur/74ujdde</h6>
            </div>
        </div>

        <div class="details-imput mt-5">
            <div class="row align-items-center">
                <div class="col-md-2">
                    <label for="fname">Name</label>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <input v-model="user.firstname" class="form-control input-style" type="text" id="fname" placeholder="No First name" />
                        </div>
                        <div class="col-md-6 form-group">
                            <input v-model="user.lastname" class="form-control input-style" type="text" id="lname" placeholder="No Last name" />
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row align-items-center mt-4">
                <div class="col-md-2">
                    <label for="email">Email Address</label>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input v-model="user.email" class="form-control input-style" type="text" id="fname" placeholder="No Email Address" />
                    </div>
                </div>
            </div>
            <hr>
            <div class="row align-items-center mt-4">
                <div class="col-md-2">
                    <label for="phone">Phone Number</label>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input v-model="user.phone" class="form-control input-style" type="text" id="fname" placeholder="No phone number" />
                    </div>
                </div>
            </div>
            <hr>
            <div class="row align-items-center">
                <div class="col-md-2">
                    <label for="fname">Residency</label>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <select class="form-control input-style" id="country">
                                <option>Nigeria</option>
                            </select>
                        </div>
                        <div class="col-md-6 form-group">
                            <select class="form-control input-style" id="state" v-model="user.cityObject.name">
                                <option :value="user.cityObject.name">{{ user.cityObject.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row align-items-center mt-4">
                <div class="col-md-2">
                    <label for="phone">Occupation</label>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input class="form-control input-style" type="text" id="fname" placeholder="No occupation" />
                    </div>
                </div>
            </div>
            <hr>
            <div class="row align-items-center mt-4">
                <div class="col-md-2">
                    <label for="phone">Account Number</label>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input v-model="user.accountnumber" class="form-control input-style" type="text" id="fname" placeholder="No account number" />
                    </div>
                </div>
            </div>
            <hr>
            <div class="row align-items-center mt-4">
                <div class="col-md-2">
                    <label for="phone">Account Name</label>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input v-model="user.accountname" class="form-control input-style disable" type="text" id="fname" placeholder="No account name" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Profile',
        component: {
        },
        data() {
            return {
               user: {},
                
            }
        },
        computed: {
            
        },
        created() {
            this.user = this.$store.getters.getUser;
            console.log(this.user)
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            
        },
        mounted() {
        },
    }

</script>


<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;

.holder {
    padding: 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #ffffff;
    border-radius: 8px;
}


.button-options {
    width: fit-content;
    background: #f5f7fe;
    border-radius: 50px;
    padding: 8px;
    button {
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        text-align: left;
        color: $asheGrey;
        border-radius: 50px;
        margin-right: 10px;
        height: 28px;
        outline: 0;
        box-shadow: none;
    }
}
.active-button {
    background: $white;
    color: $primary;
}

.profile-image-details {
    display: flex;
    align-items: center;
}
.image-details {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    object-fit: cover;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    .profile-image-holder {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50px;
    }
}
.camera-select {
    position: absolute;
    right: 0;
    background: #f9ded6;
    border-radius: 50px;
    // padding: 10px;
    bottom: 0px;
    width: 24px;
    height: 24px;
    img {
        padding-left: 3.5px;
        padding-bottom: 3.1px;
    }
}
.info-details {
    h6:first-child {
        color: $offblack;
    }
    h6:nth-child(2) {
        color: $asheGrey;
    }
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
    }
}

label {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: $asheGrey;
}

.input-style {
    border: 1px solid #e7e7e7;
    height: 48px;
    background: inherit;
    box-shadow: none;
    outline: 0;
    pointer-events:none;
}
</style>